<template>
  <v-container
    :style="
      `width: ${
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? ''
          : '1200px !important'
      };`
    "
  >
    <v-layout
      wrap
      layout>
      <v-flex
        xs12
        md5>
        <div style="width: 100%;">
          <!-- Main image -->
          <img
            :src="imageSelected"
            width="100%" >
            <!-- Sub image -->
            <!-- <div
            style="display: flex; flex: 1; overflow-x: auto; overflow-y: hidden;"
          >
            <div style="display: flex; justify-content: space-between;">
              <div
                v-for="(image, index) in albumImages"
                :key="image + index"
                style="flex: 1; width: 253px; height: 168px; padding: 5px;">
                <img
                  :src="image"
                  width="100%"
                  class="cursor-pointer"
                  @click="onSelectImage(image)">
              </div>
            </div>
          </div> -->
        </div>
      </v-flex>
      <v-flex
        xs12
        md7
        class="pl-2">
        <div>
          <b
            class="red--text font-small"
            style="float: right;">{{ $t('voucherProduct.remainItems', {count: remainQuantity}) }}</b>
          <h3
            class="title-product-size font-weight-medium"
            style="text-align: left;"
          >
            {{ name }}
          </h3>
          <div class="d-flex justify-content-around">
            <div class="font-weight-bold mt-1">
              <span
                v-if="pricePromo"
                style="color: #ff6d6d;"
              >{{ formatMoney(pricePromo) }} VND</span
              >
              <span
                :class="[`d-block`, !pricePromo ? '' : '']"
                :style="
                  pricePromo
                    ? 'text-decoration: line-through; font-size: 12px; font-weight: normal; color: #4B4B4B;'
                    : 'color: #ff6d6d;'
                "
              >{{ formatMoney(price) }} VND</span
              >
            </div>
            <div
              style="display: flex; align-items: center; justify-content: flex-end;"
            >
              <div
                class="cursor-pointer"
                style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                @click="decreaseQuantity()"
              >
                -
              </div>
              <div
                class="cursor-pointer"
                style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
              >
                {{ quantity }}
              </div>
              <div
                class="cursor-pointer"
                style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                @click="increaseQuantity()"
              >
                +
              </div>
            </div>
          </div>
          <div>
            <h4 class="my-2 font-medium">{{ shortInfo }}</h4>
          </div>
        </div>
      </v-flex>
    </v-layout>
    <!-- Product info -->
    <v-layout
      wrap
      layout>
      <v-flex
        xs12
        md12>
        <h3 class="font-weight-bold title-product-size">
          {{ $t("product.detail.productInfo") }}
        </h3>
        <div style="min-height: 300px; text-align: left;">
          <div
            class="d-flex justify-content-around"
            style="white-space: nowrap; overflow-x: auto;"
          >
            <span
              :class="[
                'font-weight-light cursor-pointer',
                tabSelected == tabs.INFO ? 'white--text' : 'white--text',
              ]"
              :style="
                (tabSelected == tabs.INFO
                  ? 'background-color: #0B4F6C;'
                  : 'background-color: #ff6d6d;') + 'padding: 6px;'
              "
              @click="onSelectTab(tabs.INFO)"
            >{{ $t("evoucher.issuance.description") }}
            </span>
            <span
              :class="[
                'font-weight-light cursor-pointer mx-1',
                tabSelected == tabs.CONDITIONS_OF_USE
                  ? 'white--text'
                  : 'white--text',
              ]"
              :style="
                (tabSelected == tabs.CONDITIONS_OF_USE
                  ? 'background-color:  #0B4F6C;'
                  : 'background-color: #ff6d6d;') + 'padding: 6px;'
              "
              @click="onSelectTab(tabs.CONDITIONS_OF_USE)"
            >{{ $t("product.detail.conditionsOfUse") }}
            </span>
            <span
              :class="[
                'font-weight-light cursor-pointer',
                tabSelected == tabs.ADDRESS ? 'white--text' : 'white--text',
              ]"
              :style="
                (tabSelected == tabs.ADDRESS
                  ? 'background-color:  #0B4F6C;'
                  : 'background-color: #ff6d6d;') + 'padding: 6px;'
              "
              @click="onSelectTab(tabs.ADDRESS)"
            >{{ $t("product.detail.locationOfUse") }}
            </span>
          </div>
          <div
            v-if="tabSelected == tabs.INFO"
            class="font-medium mt-2">
            <p style="white-space: pre-wrap;">{{ description }}</p>
            <p style="white-space: pre-wrap;">{{ agentDescription }}</p>
          </div>
          <div
            v-else-if="tabSelected == tabs.CONDITIONS_OF_USE"
            class="font-medium mt-2"
            style="white-space: pre-wrap;"
          >
            {{ conditionsOfUse }}
          </div>
          <div
            v-for="(address, index) in usageSites"
            v-else
            :key="address + index"
            class="font-medium mt-2"
          >
            <span class="d-block font-weight-bold">{{ index + 1 + ". " + address.name }}</span>
            <div
              v-if="address.fullAddress"
              class="d-inline-flex">
              <address-label
                :longitude="address.latitude"
                :latitude="address.longitude">
                {{ address.fullAddress }}
              </address-label>
              <br >
            </div>
            <div
              v-if="address.email"
              class="d-inline-flex">
              <v-icon
                class="ml-2 mr-1"
                size="16">mdi-email</v-icon>
              <span
              ><a :href="`mailto:${address.email}`">{{
                address.email
              }}</a></span
              >
              <br >
            </div>
            <div
              v-if="address.phone"
              class="d-inline-flex">
              <v-icon
                class="ml-2 mr-1"
                size="16">mdi-phone</v-icon>
              <span
              ><a :href="`tel:${address.phone}`">{{ address.phone }}</a></span
              >
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
    <v-btn
      style="margin: 0; width: 100%; background-color: #ff6d6d; color: #fff;"
      @click="onBuyVoucher"
    >
      {{ $t("thirdParty.chooseBuy") }}
    </v-btn>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <v-dialog
      v-model="dialog"
      max-width="390">
      <v-card>
        <v-card-title class="headline">{{
          $t("common.notification")
        }}</v-card-title>

        <v-card-text>
          {{ $t("thirdParty.productHasBeenAddedToCart") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            style="color: #fff; background-color: #01baef;"
            @click="onGoToCart()"
          >
            {{ $t("thirdParty.viewCart") }}
          </v-btn>
          <v-btn
            style="color: #fff; background-color: #ff6d6d;"
            @click="onContinueBuyinng()"
          >
            {{ $t("thirdParty.continueBuy") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import { MAX_DISPLAY_DESCRIPTION_TEXT } from 'utils/constants'
import ToastType from 'enum/toastType'
import StoreChannelType from 'enum/storeChannelType'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import AddressLabel from 'Components/AddressLabel.vue'

export default {
  components: {
    AddressLabel
  },
  data: () => ({
    name: null,
    remainQuantity: null,
    price: null,
    pricePromo: null,
    quantity: 1,
    isShowDescription: true,
    conditionsOfUse: null,
    description: null,
    shortInfo: null,
    imageUrl: null,
    id: null,
    albumImages: [],
    imageSelected: null,
    code: null,
    indexRetrievingAddessUsage: 0,
    usageSites: [],
    tabs: {
      INFO: 1,
      CONDITIONS_OF_USE: 2,
      ADDRESS: 3
    },
    tabSelected: 1,
    agentDescription: null,
    dialog: false,
    agentDefaultId: null,
    issuanceId: null,
    numberOfUsesVoucherValid: 0
  }),
  computed: {
    ...mapGetters([
      'MOBILE_PORTAL_CARTS_DATA',
      'GET_SNACK_BAR'
    ])
  },
  async created () {
    let productId = this.$route.params.id
    let queryRouter = this.$route.query
    let issuanceId = queryRouter && queryRouter.issuanceId
    this.issuanceId = issuanceId
    let filter = {
      params: {
        storeChannelId: this.getStoreChannelId(),
        issuanceId: issuanceId
      }
    }
    await this.GET_PRODUCT_DETAIL({ id: productId, ...filter }).then(
      function (res) {
        this.albumImages = []
        let data = res.data
        this.name = data.name
        this.remainQuantity = data.warehouse
        this.price = data.value
        this.pricePromo = data.extra_info.product ? data.extra_info.product.price_promo : null
        this.imageUrl = data.image_url
        this.id = data.id
        this.code = data.code
        // Handle get images
        let supplierImages = data.extra_info.supplier ? data.extra_info.supplier.album_image_urls : null
        let agentImage = data.extra_info.agent ? data.extra_info.agent.image_url : null
        let agentAlbumImages = data.extra_info.agent ? data.extra_info.agent.album_image_urls : null
        if (this.imageUrl) {
          this.albumImages.push(this.imageUrl)
        }
        if (supplierImages) {
          this.albumImages.push(...supplierImages)
        }
        if (agentImage) {
          this.albumImages.push(agentImage)
        }
        if (agentAlbumImages) {
          this.albumImages.push(...agentAlbumImages)
        }
        if (this.albumImages.length > 0) {
          this.imageSelected = this.albumImages[0]
        }
        this.conditionsOfUse = data.conditions_of_use
        this.description = data.description
        this.shortInfo = functionUtils.ellipsisLongText(
          data.description,
          2 * MAX_DISPLAY_DESCRIPTION_TEXT
        )
        this.agentDescription = data.extra_info.agent ? data.extra_info.agent.description : ''
        this.usageSites = []
        data.usage_sites.forEach((site) => {
          let item = {
            id: site.id,
            name: site.name,
            code: site.code,
            longitude: site.longitude,
            latitude: site.latitude,
            province: site.province,
            district: site.district,
            ward: site.ward,
            street: site.street,
            fullAddress: site.street
          }
          this.usageSites.push(item)
        })
        this.indexRetrievingAddessUsage = 0
        this.numberOfUsesVoucherValid = data.number_of_uses_voucher_valid
        this.doRetrievingFullAddressUsage()
      }.bind(this)
    )
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    /**
     * Go to cart
     */
    onGoToCart: function () {
      this.dialog = false
      this.$router.push({
        name: 'MobilePortalCart'
      })
    },
    /**
     * Back to products list
     */
    onContinueBuyinng: function () {
      this.dialog = false
      window.history.back()
    },
    /**
     * Select tab
     */
    onSelectTab: function (tab) {
      this.tabSelected = tab
    },
    /**
     * Select image
     */
    onSelectImage: function (image) {
      this.imageSelected = image
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      if (price) {
        return functionUtils.convertFormatNumberToMoney(price.toString())
      } else {
        return 0
      }
    },
    /**
     * Buy voucher
     */
    onBuyVoucher: function () {
      if (this.numberOfUsesVoucherValid !== null && this.numberOfUsesVoucherValid < this.quantity) {
        this.ON_SHOW_TOAST({
          'message': this.$t('cart.productCanBuyTimes', { '0': this.numberOfUsesVoucherValid }),
          'styleType': ToastType.ERROR
        })
        return
      }
      if (this.quantity < 1) {
        this.ON_SHOW_TOAST({
          message: this.$t('cart.quantityMustGreatThanZero'),
          styleType: ToastType.ERROR
        })
        return
      }
      if (this.quantity > this.remainQuantity) {
        this.ON_SHOW_TOAST({
          message: this.$t('cart.addToCartOverQuantity'),
          styleType: ToastType.ERROR
        })
        return
      }
      // let productInfo2 = {
      //   id: this.id,
      //   image: this.imageUrl,
      //   quantity: this.quantity,
      //   price: this.price,
      //   pricePromo: this.pricePromo,
      //   name: this.name,
      //   code: this.code
      // }
      let productInfo = {
        workpieceId: this.id,
        quantity: this.quantity,
        storeChannelId: this.getStoreChannelId(),
        issuanceId: this.issuanceId
      }
      this.ADD_VOUCHER_ORDER_CART(productInfo).then(
        function () {
          this.getVoucherOrderCart()
          this.dialog = true
        }.bind(this)
      )
    },
    doRetrievingFullAddressUsage: function () {
      if (
        this.usageSites &&
        this.indexRetrievingAddessUsage < this.usageSites.length
      ) {
        let addressFilter = {
          params: {
            countryCode: 84,
            cityCode: this.usageSites[this.indexRetrievingAddessUsage]
              .province,
            districtCode: this.usageSites[this.indexRetrievingAddessUsage]
              .district,
            wardCode: this.usageSites[this.indexRetrievingAddessUsage].ward
          }
        }
        this.GET_ADDRESS_DETAIL(addressFilter).then(
          function (res) {
            let fullAddress = functionUtils.concatFullAddress(
              res.data,
              this.usageSites[this.indexRetrievingAddessUsage].street
            )
            this.usageSites[this.indexRetrievingAddessUsage].fullAddress = fullAddress
            // get update next item if any
            this.indexRetrievingAddessUsage++
            this.doRetrievingFullAddressUsage()
          }.bind(this)
        )
      }
    },
    /**
     * Increase quantity
     */
    increaseQuantity: function () {
      if (this.numberOfUsesVoucherValid !== null && this.numberOfUsesVoucherValid < this.quantity + 1) {
        this.ON_SHOW_TOAST({
          'message': this.$t('cart.productCanBuyTimes', { '0': this.numberOfUsesVoucherValid }),
          'styleType': ToastType.ERROR
        })
      } else if (this.quantity + 1 <= this.remainQuantity) {
        this.quantity = this.quantity + 1
      }
    },
    decreaseQuantity: function () {
      if (this.quantity > 0) {
        this.quantity = this.quantity - 1
      }
    },
    getVoucherOrderCart: function () {
      let filter = {
        params: {
          storeChannelId: this.getStoreChannelId()
        }
      }
      this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return partnershipEntityId
      }
      return entityId
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    getStoreChannelId: function () {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return StoreChannelType.PRODUCT_PORTAL
      }
      return this.storeChannelsIdInRouteParams()
    },
    ...mapActions([
      'GET_PRODUCT_DETAIL',
      'ADD_VOUCHER_ORDER_CART',
      'ON_SHOW_TOAST',
      'GET_ADDRESS_DETAIL',
      'GET_VOUCHER_ORDER_CART_LIST',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
